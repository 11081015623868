//"nothing here"
import Splide from '@splidejs/splide';

var main = new Splide( '#main-carousel', {
  type: 'fade',
  pagination: false,
  arrows    : false,
} );

var thumb = new Splide( '#thumbnail-carousel', {
  direction   : 'ttb',
  pagination: false,
  arrows    : false,
  height: 300,
  fixedHeight : 'auto',
  isNavigation: true,
  gap        : 30,
  breakpoints: {
    720: {
      direction   : 'ltr',
      pagination: true,
      focus      : 'center',
      trimSpace: false,
      height: 200,
      width: '95%',
      fixedHeight : 29,
      fixedWidth: 'auto'
    },
  },
} );

main.sync( thumb );
main.mount();
thumb.mount();

/* let index = 1;

var interval = setInterval(() => {
  let element = document.querySelector('#thumbnail-carousel .is-active');
  let split = element.getAttribute('aria-label').split(" ");
  let id = split[split.length-1];
  let elements = document.getElementsByClassName('splide__slide');
  let list = elements.length / 2;

  if (id == list ) {
    document.getElementById('thumbnail-carousel-slide01').click()
    clearInterval(interval)
  } else {
    index++
    document.getElementById('thumbnail-carousel-slide0'+ index +'').click()
  }
  

}, 5000);

document.querySelector('.splide__slide').addEventListener('click', function() {
  console.log('click')
  clearInterval(interval)
}) */
